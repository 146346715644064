/* eslint-disable react/display-name */
import React, {useContext} from 'react';
import {IStylesParams} from './baseStylesParams';

const StylesParamsContext = React.createContext<IStylesParams>({} as IStylesParams);

export function withStylesParams(Component, stylesParams) {
  return (props) => {
    return (
      <StylesParamsContext.Provider value={stylesParams}>
        <Component {...props} />
      </StylesParamsContext.Provider>
    );
  };
}

export function useStylesParams() {
  return useContext(StylesParamsContext);
}
