import React from 'react';
import {withSlotsPlaceholders} from '@wix/widget-plugins-ooi';
import {IStylesParams} from './baseStylesParams';
import {initContext, Strategy, withGlobalsProvider} from './globalPropsContext';
import {withStylesParams} from './stylesParamsContext';

export interface IGetWrappedComponentOptions {
  Component: React.ComponentType;
  globalPropsStrategy: Strategy<any>;
  sliderGallery?: boolean;
  stylesParams: IStylesParams;
  withSlots?: boolean;
}

export function getWrappedComponent({
  Component,
  globalPropsStrategy,
  sliderGallery,
  stylesParams,
  withSlots,
}: IGetWrappedComponentOptions) {
  initContext(globalPropsStrategy);
  const ComponentWithGlobals = withGlobalsProvider(Component, sliderGallery);
  const ComponentWithGlobalsAndStyleParams = withStylesParams(ComponentWithGlobals, stylesParams);
  return withSlots ? withSlotsPlaceholders(ComponentWithGlobalsAndStyleParams) : ComponentWithGlobalsAndStyleParams;
}
